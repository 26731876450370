<template>
  <div v-click-outside="onBlur" class="v-password-input-mts">
    <input
      v-bind="$attrs"
      :type="type"
      class="v-password-input-mts__input"
      @input="updateValue"
      @focus="onFocus"
    />
    <span :class="['v-password-input-mts__eye']" @click="toggleVisibility"
      ><VIconMTS
        :name="`mts/icon-mts--${
          type === 'text' ? 'field-eye-hide' : 'field-eye'
        }`"
        width="24"
        height="24"
        fill="#969FA8"
    /></span>
    <div
      v-if="!currentValue || currentValue < 8"
      class="v-password-input-mts__info"
    >
      Введите не менее 8 символов без пробелов
    </div>
  </div>
</template>

<script>
import VIconMTS from './VIconMTS.vue'
import clickOutside from '@/directives/clickOutside'

export default {
  name: 'VPasswordInputMTS',
  components: { VIconMTS },
  directives: { clickOutside },
  data() {
    return {
      type: 'password',
      isFocused: false,
    }
  },
  computed: {
    currentValue() {
      if (this.$attrs.value) return this.$attrs.value.length
    },
  },
  methods: {
    setValue(value) {
      this.$emit('input', value)
    },
    updateValue(event) {
      this.setValue(event.target.value)
    },
    clearValue() {
      this.$attrs.value = ''
      this.setValue('')
    },
    onFocus() {
      this.isFocused = true
      this.$emit('focus')
    },
    onBlur() {
      this.isFocused = false
    },
    toggleVisibility() {
      this.type = this.type === 'password' ? 'text' : 'password'
    },
  },
}
</script>

<style lang="scss" scoped>
.v-password-input-mts {
  font-family: $font-mts-compact;
  position: relative;

  &.error {
    &:after {
      content: '';
      position: absolute;
      right: 12px;
      top: calc(50% - 12px);
      display: block;
      background-image: url('~/assets/img/icons/mts/icon-mts--field-error.svg');
      background-size: 24px 24px;
      width: 24px;
      height: 24px;
      transition: all 0.3s ease;
    }

    .v-password-input-mts__input {
      border-color: #f95721;
    }
  }

  &__input {
    display: inline-block;
    height: 44px;
    width: 100%;
    background: $color-gray-2;
    border: 1px solid #bcc3d080;
    border-radius: 6px;
    outline: none;
    padding: 10px 12px;
    box-shadow: none;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    color: $color-dark-2;
    transition: all 0.3s ease;

    &:not(:focus):not(.error &):hover {
      border-color: $color-dark-2;
    }

    &:focus {
      transition: all 0.3s ease;
      border-color: $color-blue;

      .error & {
        border-color: $color-blue;

        & + .v-password-input-mts__eye {
          display: block;
        }
      }

      &::placeholder {
        color: #969fa8;
      }
    }

    &::placeholder {
      color: $color-gray-1;
    }

    .error & {
      padding-right: 45px;
      border: 1px solid $color-red-3;
    }
    .xl & {
      height: 52px;
      padding: 13px 12px;
    }
  }

  &__eye {
    display: block;
    position: absolute;
    right: 12px;
    top: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    .error & {
      display: none;
    }

    .xl & {
      top: calc(0% + 12px);
    }
    // &.--visible {
    //   background-image: url('~/assets/img/icons/mts/icon-mts--field-eye.svg');
    // }
    // &.--hide {
    //   background-image: url('~/assets/img/icons/mts/icon-mts--field-eye-hide.svg');
    // }
  }

  &__info {
    color: $color-gray-1;
    font-family: $font-mts-compact;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 4px;

    .error & {
      display: none;
    }
  }

  &__error {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    color: $color-red-3;
  }
}
</style>
